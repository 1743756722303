<template>
    <div class="share">
        <!--农家乐-->
        <div class="w-1400 njl">
            <div class="pagetitle clearfix">
                <div class="fl f-label">农庄推荐</div>
                <router-link to="/sharehouselist?businessType=农家乐"> <div class="fr more">更多<span class="el-icon-arrow-right"></span></div></router-link>
            </div>
            <div class="njl-main mt-20">
                <div class="njl-l">
                    <div class="njl-menu-item" v-for="(v,k) in njlMenu" :key="k+'njlmenu'" @click="onNjlMenuChange(v)" :class="{act:v.no===njlact}" :style="`background-image:url(${v.url})`">
                        <!-- {{v.title}} -->
                        <div class="ceng">
                            {{v.title}}
                        </div>
                    </div>
                </div>
                <div class="njl-r" v-loading="Loading">
                    <el-row :gutter="20">
                        <el-col :span="8" v-for="item in sharedFarmsNjl" :key="item.id">
                            <div class="njl-list" @click="itemRoutr(item.id)">
                                <div class="njl-pic">
                                    <el-image style="width:100%;height:100%" :src="item.photoUrls[0]"></el-image>
                                </div>
                                <div class="njl-item-info">
                                    <div class="njl-title ellipsis">{{item.name}}</div>
                                    <div class="njl-mark row">
                                        <div v-for="vc in item.playItems.slice(0,3)" :key="vc + item.id">{{vc}}</div>
                                        <!-- <div v-if="item.playItems[0]">{{item.playItems[0]}}</div>
                                        <div v-if="item.playItems[1]">{{item.playItems[1]}}</div>
                                        <div v-if="item.playItems[2]">{{item.playItems[2]}}</div> -->
                                        <div v-if="item.playItems&&item.playItems.length>3">···</div>
                                    </div>
                                    <div class="region ellipsis">
                                        {{item.address}}
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
        <!--精品民宿-->


        <div class="homes w-1400" v-loading="Loading2">
            <div class="homes-1f clear-fix" >
                <div class="homes-logo fl">
                    <div class="homes-lg-title ellipsis">精品民宿</div>
                    <div class="hotm-lg-pic">
                        <el-image :src="require('../../../public/img/xnh/homes1.png')" style="width:100%;height:100%" fit="cover"></el-image>
                    </div>
                </div>
                <div class="homes-menu fl">
                    <div class="menu-list">
                        <ul class="clearfix">
                            <li :class="jpmsact==='1'?'fl isact':'fl'" @click="onJpmsChange('1', '亲子游')"><span>亲子</span></li>
                            <li :class="jpmsact==='2'?'fl isact':'fl'" @click="onJpmsChange('2', '情侣游')"><span>情侣</span></li>
                            <li :class="jpmsact==='3'?'fl isact':'fl'" @click="onJpmsChange('3', '团体游')"><span>团体</span></li>
                            <li :class="jpmsact==='4'?'fl isact':'fl'" @click="onJpmsChange('4', '家庭游')"><span>家庭</span></li>
                        </ul>
                        <div class="fr">
                            <router-link to="/sharehouselist?businessType=精品民宿">更多<i class="el-icon-arrow-right"></i></router-link>
                        </div>
                    </div>
                    <div class="homes-1f-list">
                        <el-row :gutter="20">
                            <el-col :span="8" v-for="item in SharedFarmsJpms1" :key="item.id">
                                <div class="homes-pic">
                                    <router-link :to="`/sharehousedetail?id=${item.id}`">
                                        <el-image :src="item.photoUrls[0]" style="width:100%;height:100%" fit="cover"></el-image>
                                    </router-link>
                                </div>
                                <div class="homes-1f-h2 ellipsis">
                                    <div class="ellipsis">{{item.name}}</div>
                                    <div ><span>{{item.evaluateNumber}}</span>评论</div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                    
                </div>
            </div>
            <div class="homes-2f mt-20">
                <el-row :gutter="20">
                    <el-col :span="6" v-for="item in SharedFarmsJpms2" :key="item.id">
                        <div class="homes-2f-pic">
                             <router-link :to="`/sharehousedetail?id=${item.id}`">
                            <el-image :src="item.photoUrls[0]" style="width:100%;height:100%" fit="cover"></el-image>
                             </router-link>>
                        </div>
                        <div class="homes-1f-h2">
                            <div class="ellipsis">{{item.name}}</div>
                            <div><span>{{item.evaluateNumber}}</span>评论</div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>
<script>
import { GetSharedFarmPage,GetSharedFarmPageV2 } from '@/api/home'
export default {
    data(){
        return{
            njlMenu: [
                {url:require('../../assets/img/njl1.jpg'),title:'农家乐',no:'1'},
                {url:require('../../assets/img/njl2.png'),title:'特色农庄',no:'2'},
                {url:require('../../assets/img/njl3.jpg'),title:'生态园',no:'3'},
                {url:require('../../assets/img/njl4.jpg'),title:'采摘园',no:'4'}
            ],
            njlact: '1',
            sharedFarmsNjl: [], //农家乐
            SharedFarmsJpms1: [], //精品民宿1
            SharedFarmsJpms2: [], //精品民宿2
            jpmsact: '1',
            Loading:false,
            Loading2:false
        }
    },
    mounted(){
        this.getSharedFarmsNjl();
        this.getSharedFarmsJpms('亲子游');
    },
    methods:{
        onNjlMenuChange(item) {
            this.njlact = item.no;
            this.getSharedFarmsNjl();
        },
        //获取共享农庄/农家乐
        getSharedFarmsNjl() {
            // var dynamicFilter = null;
            // switch (this.njlact) { //!!!根据实际使用情况做修改。
            //     case '1':
            //         dynamicFilter = {field:"playItems",operator:'Contains',value:"农家饭"};
            //         break;
            //     case '2':
            //         dynamicFilter = {field:"playItems",operator:'Contains',value:"住宿"};
            //         break;
            //     case '3':
            //         dynamicFilter = {field:"playItems",operator:'Contains',value:"%"};
            //         break;
            //     case '4':
            //         dynamicFilter = {field:"playItems",operator:'Contains',value:"采摘"};
            //         break;
            //     default:
            //         dynamicFilter = {field:"playItems",operator:'Contains',value:""};
            // }
            let type = this.njlMenu.find(n=>n.no==this.njlact).title
            const data = { filter:{businessType:type}, currentPage:1, pageSize:6};
            this.Loading = true;
            GetSharedFarmPage(data).then(res => {
                if (res.data?.success == true) {
                    const data = res.data.data.list;
                    data.forEach(d => {
                        d.photoUrls = d.photoUrls.split(',');
                        d.playItems = d.playItems.split(',');
                        // if (d.playItems.length > 3) {
                        //     d.playItems = d.playItems.splice(0, 3); //最多显示3个。
                        // }
                    })
                    this.sharedFarmsNjl = data;
                    this.Loading = false
                }
            })
        },
        onJpmsChange(index, people) {
            this.jpmsact = index;
            this.getSharedFarmsJpms(people);
        },
        //获取共享农庄/精品民宿
        getSharedFarmsJpms(people) {
            //var dynamicFilter = {field:"suitablePeople",operator:'Contains',value:people}; //V2版暂不支持dynamicFilter
            const data = { filter:{businessType:'精品民宿',suitablePeople:people},/* dynamicFilter:dynamicFilter,*/ currentPage:1, pageSize:7 };
            this.Loading2 = true;
            GetSharedFarmPageV2(data).then(res => {
                if (res.data?.success == true) {
                    const data = res.data.data.list;
                    data.forEach(d => {
                        d.photoUrls = d.photoUrls.split(',');
                    })
                    this.SharedFarmsJpms1 = data;
                    if (this.SharedFarmsJpms1.length > 3) {
                        this.SharedFarmsJpms2 = this.SharedFarmsJpms1.splice(3, this.SharedFarmsJpms1.length-3);
                    } else {
                        this.SharedFarmsJpms2 = [];
                    }
                }
                this.Loading2 = false;
            })
        },
        itemRoutr(id){
            this.$router.push(`/sharehousedetail?id=${id}`)
        }
    },
}
</script>
<style scoped>
.region{
    color: #999;
    text-align: right;
    font-size: 15px;
    line-height: 30px;
    padding-right: 10px;
    font-weight: 600;
}
.njl-mark>div:nth-child(1n){color:#0169ca;border-color: #0169ca;}
.njl-mark>div:nth-child(2n){color:#15be35;border-color: #15be35;}
.njl-mark>div:nth-child(3n){color:#ec6941;border-color: #ec6941;}
.njl-mark>div{
    font-size: 16px;
    font-weight: 600;
    padding: 5px 8px;
    border-width: 1px;
    border-style: solid;
    margin-right: 10px;
}
.njl-mark{
    margin: 10px 0; 
    height: 32px;
    overflow: hidden;
}
.njl-item-info{
    padding: 5px;
}
.njl-title{
    line-height: 30px;
    font-size: 20px;
    font-weight: 700;
}
.njl-pic{
    height: 172px;
    overflow: hidden;
}
.njl-list{
    border: 1px solid #ccc;
    margin-bottom:20px;
    border-radius: 3px;
    cursor: pointer;
}
.njl-r{
    margin-left: 300px;
    height: 630px;
    overflow: hidden;
}
.njl-menu-item.act .ceng{
    background: transparent;
}
.ceng{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .5);
}
.njl-menu-item.act{
    height: 145px;
    line-height: 145px;
}
.njl-menu-item{
    height: 80px;
    line-height: 80px;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #fff;
    width: 280px;
    background-position: center center;
    position: relative;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s;
}
.njl-main{
    position: relative;
    min-height: 420px;
}
.njl-l{
    width: 280px;
    height: 320px;
    position: absolute;
    left: 0;
    top: 0;
}
.mt-20{
    margin-top: 20px;
}
.homes-2f{
    height: 280px;
}
.homes-2f-pic{
    height: 240px;
    width: 100%;
    overflow: hidden;
}
.homes-1f-h2{
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    display: flex;
    font-weight: 600;
    justify-content: space-between;
    background: #eee;
    padding: 0 5px;
}
.homes-1f-h2>div:first-child{
    width: 200px;
}
.homes-1f-h2>div:last-child{
    width: calc(100% - 200px);
    text-align: right;
    font-size: 16px;
}
.homes-1f-h2>div:last-child>span{
    color: #fe620d;
    margin-right: 4px;
}
.homes-pic{
    height: 230px;
    width: 100%;
}
.homes-1f-list{
    height: 270px;
    padding-left: 10px;
}
.menu-list ul{
    position: absolute;
    top: 0;
    left: 0;
    
}
.menu-list li.isact span{
    color: #fe620d;
}
.menu-list li span{
    padding:0px 50px;
    border-right: 1px solid #ccc;
    line-height: 20px;
}
.menu-list li{
    cursor: pointer;
}
.menu-list{
    height: 50px;
    position: relative;
    line-height: 50px;
    font-size: 20px;
    margin-left: 5px;
}
.homes-menu{
    width: calc(100% - 235px);
    border-top: 1px solid #ccc;
    font-weight: 600;
}
.homes-lg-title{
    height: 50px;
    line-height: 50px;
    font-size: 22px;
    font-weight: 600;
    color: #fff;
    background: #fe620d;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    text-align: center;
}
.hotm-lg-pic{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.homes-logo{
    height: 100%;
    width: 230px;
    position: relative;
}
.homes-1f{
    height: 320px;
}
.r-pic-box{
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.f-label{
    color: #0169ca;
}
.pagetitle .more{
    cursor: pointer;

}
.pagetitle{
    height: 42px;
    line-height: 42px;
    font-size: 20px;
    font-weight: 700;
    border-bottom: 1px solid #ccc;
    padding: 0 4px;
}
</style>